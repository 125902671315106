<div id="admin_setting" class="admin-page">
  <h3 class="title is-3">Pengaturan Hadiah</h3>
  <div class="columns is-multiline text-left mb-6">
    <div class="column is-12">
      <div class="field">
        <label class="label">Id Hadiah</label>
        <div class="control">
          <input class="input" type="text" v-model="inputData.id" disabled />
        </div>
      </div>

      <div class="field">
        <label class="label">Nama Hadiah</label>
        <div class="control">
          <input class="input" type="text" placeholder="Nama Hadiah" v-model="inputData.prizename" />
        </div>
      </div>

      <div class="field">
        <label class="label">Gambar Hadiah</label>
        <div class="control">
          <input class="input" type="text" placeholder="Url Gambar Hadiah" v-model="inputData.image_url" />
        </div>
      </div>

      <div class="field mt-4">
        <label class="checkbox">
          <input type="checkbox" v-model="inputData.active" />
          Aktif
        </label>
        <p class="help is-danger is-italic">Hadiah yang tidak aktif tidak akan ditampilkan dan tidak bisa dimenangkan
        </p>
      </div>

      <div class="field">
        <label class="label">Urutan</label>
        <div class="control">
          <input class="input" type="number" min="1" placeholder="Beban" v-model="inputData.order" />
        </div>
      </div>

      <div class="field">
        <label class="label">Beban</label>
        <div class="control">
          <input class="input" type="number" min="1" placeholder="Beban" v-model="inputData.weight" />
        </div>
        <p class="help is-danger is-italic">Beban adalah peluang munculnya hadiah, makin besar maka makin besar juga
          peluang terpilih (Cek bagian persentase kemenangan di tabel dibawah untuk lebih jelas)</p>
      </div>

      <div class="field">
        <label class="label">Dikunci Sampai</label>
        <div class="control">
          <input class="input" type="number" min="1" placeholder="Dikunci Sampai" v-model="inputData.locked_for" />
        </div>
        <p class="help is-danger is-italic">Dikunci sampai putaran ke berapa (berlaku kumulatif).
          Jika dikunci pada putaran ke 3 maka hadiah hanya akan punya peluang dimenangkan tiap putaran ke 3
          (putaran ke 1 dan ke 2 tidak akan bisa menang hadiah ini)</p>

        <button class="button is-link is-light help" @click="toggleExplanation">Tampilkan penjelasan</button>

        <div v-if="showExplanation" id="example-explanation">
          <p class="help is-italic">
            Contoh: Ada 4 Hadiah, A, B, C, D (Semuanya punya beban 1)<br>
            Hadiah D dikunci sampai putaran ke 3 <br>
          </p>
          <p class="help is-italic">
            Putaran 1 (Tidak ada hadiah D):
          <table class="table help">
            <tr>
              <td>Hadiah</td>
              <td>Beban</td>
              <td>Peluang Menang</td>
            </tr>
            <tr>
              <td>A</td>
              <td>1</td>
              <td>33%</td>
            </tr>
            <tr>
              <td>B</td>
              <td>1</td>
              <td>33%</td>
            </tr>
            <tr>
              <td>C</td>
              <td>1</td>
              <td>33%</td>
            </tr>
          </table>
          </p>

          <p class="help is-italic">
            Putaran 2 (Tidak ada hadiah D):
          <table class="table help">
            <tr>
              <td>Hadiah</td>
              <td>Beban</td>
              <td>Peluang Menang</td>
            </tr>
            <tr>
              <td>A</td>
              <td>1</td>
              <td>33%</td>
            </tr>
            <tr>
              <td>B</td>
              <td>1</td>
              <td>33%</td>
            </tr>
            <tr>
              <td>C</td>
              <td>1</td>
              <td>33%</td>
            </tr>
          </table>
          </p>

          <p class="help is-italic">
            Putaran 3 (Ada hadiah D karena putaran sudah kelipatan 3):
          <table class="table help">
            <tr>
              <td>Hadiah</td>
              <td>Beban</td>
              <td>Peluang Menang</td>
            </tr>
            <tr>
              <td>A</td>
              <td>1</td>
              <td>25%</td>
            </tr>
            <tr>
              <td>B</td>
              <td>1</td>
              <td>25%</td>
            </tr>
            <tr>
              <td>C</td>
              <td>1</td>
              <td>25%</td>
            </tr>
            <tr>
              <td>D</td>
              <td>1</td>
              <td>25%</td>
            </tr>
          </table>
          </p>
        </div>
      </div>


    </div>
    <div class="column">
      <button class="button is-danger mr-1" @click="cancelEdit()" v-bind:disabled="!editButtonStatus">
        Batal Edit
      </button>
      <button class="button is-success" @click="doSave()">Simpan Data</button>
    </div>
  </div>

  <div class="mt-6">
    <Pagination :pagination="pagination" @paginate:goto="updatePage">
      <div class="table-container">
        <table class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth has-text-left">
          <thead>
            <tr>
              <th>Aksi</th>
              <th>Urutan</th>
              <th>Nama Hadiah</th>
              <th>Aktif</th>
              <th>Beban</th>
              <th>Peluang Menang</th>
              <th>Dikunci Sampai</th>
            </tr>
          </thead>
          <tfoot>
            <tr>
              <th>Aksi</th>
              <th>Urutan</th>
              <th>Nama Hadiah</th>
              <th>Aktif</th>
              <th>Beban</th>
              <th>Peluang Menang</th>
              <th>Dikunci Sampai</th>
            </tr>
          </tfoot>
          <tbody>
            <tr v-for="(data, index) in tableData" :key="index">
              <td class="has-text-centered">
                <button class="button is-success mr-1" @click="doEdit(data)">
                  Edit
                </button>
                <button class="button is-danger" @click="doDelete(data)">
                  Hapus
                </button>
              </td>
              <td>{{data.order}}</td>
              <td>{{data.prizename}}</td>
              <td>{{data.active}}</td>
              <td>{{data.weight}}</td>
              <td>{{data.winPercentage}} %</td>
              <td>{{data.locked_for}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Pagination>
  </div>
</div>