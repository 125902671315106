import BaseServices from "./base_services";
const RESOURCE_NAME = "/prize";

class JenisPasaranServices extends BaseServices {
  constructor(moduleName) {
    super(moduleName);
  }
}

export default new JenisPasaranServices(RESOURCE_NAME);
