import { ref, onMounted } from "vue";
import { handleError } from "@/utils/errorHandler";
import PrizeService from "@/services/prize_service";
import Pagination from "@/components/PaginationTable.vue";

export default {
  name: "Provider Admin",
  components: { Pagination },
  setup(props, context) {
    const showExplanation = ref(false);
    const inputData = ref({
      id: undefined,
      prizename: undefined,
      active: true,
      order: 1,
      weight: 1,
      locked_for: 1,
    });

    const editButtonStatus = ref(false);

    const tableData = ref([
      {
        id: 0,
        prizename: "",
        active: true,
        weight: 1,
        winPercentage: 100,
        locked_for: 1,
      },
    ]);

    const pagination = ref({
      page: 1,
      limit: 40,
      firstPage: 1,
      lastPage: 1,
      currentPage: 1,
    });

    const updatePage = (value) => {
      pagination.value.page = value;
      fetchData();
    };

    const fetchData = async () => {
      try {
        let response = await PrizeService.getAll(
          pagination.value.page,
          pagination.value.limit
        );
        tableData.value = response.data.data.rows;
        pagination.value.firstPage = response.data.data.firstPage;
        pagination.value.lastPage = response.data.data.lastPage;
        pagination.value.currentPage = response.data.data.currentPage;
        getPrizePercentage();
      } catch (error) {
        handleError(context, error);
      }
    };

    onMounted(async () => {
      fetchData();
    });

    const doEdit = (data) => {
      inputData.value = { ...data };
      editButtonStatus.value = true;
    };

    const cancelEdit = () => {
      inputData.value = {
        id: undefined,
        prizename: undefined,
        active: true,
        order: 1,
        weight: 1,
        locked_for: 1,
      };
      editButtonStatus.value = false;
    };

    const doDelete = async (data) => {
      let confirmation = confirm(
        `Yakin menghapus data ${data.prizename}?\nData yang dihapus tidak dapat dikembalikan`
      );
      if (confirmation) {
        try {
          let response = await PrizeService.deleteData(data.id);
          if (response.data.success) {
            fetchData();
            alert("Data berhasil dihapus");
          }
        } catch (error) {
          handleError(context, error);
        }
      }
    };

    const doSave = () => {
      let confirmation = confirm(`Yakin menyimpan data ?`);
      if (confirmation) {
        if (inputData.value.id) {
          updateExistingData();
        } else {
          createNewData();
        }
        cancelEdit();
        fetchData();
      }
    };

    const createNewData = async () => {
      try {
        let response = await PrizeService.createData(inputData.value);
        if (response.data.success) {
          fetchData();
          alert("Data berhasil disimpan");
        }
      } catch (error) {
        handleError(context, error);
      }
    };

    const updateExistingData = async () => {
      try {
        let response = await PrizeService.updateData(
          inputData.value.id,
          inputData.value
        );
        if (response.data.success) {
          fetchData();
          alert("Data berhasil diubah");
        }
      } catch (error) {
        handleError(context, error);
      }
    };

    const getPrizePercentage = async () => {
      // Filter active prizes
      const activePrizes = tableData.value.filter((prize) => prize.active);

      // Calculate the total weight of all active prizes
      const totalWeight = activePrizes.reduce(
        (sum, prize) => sum + prize.weight,
        0
      );

      // Calculate the win percentage for each active prize and update the data
      const prizesWithWinPercentage = tableData.value.map((prize) => ({
        ...prize,
        winPercentage: prize.active
          ? parseFloat(((prize.weight / totalWeight) * 100).toFixed(2))
          : 0,
      }));

      // Update the data with the calculated win percentages
      tableData.value = prizesWithWinPercentage;
    };

    const toggleExplanation = () => {
      console.log("TOGGLE :::", showExplanation.value);
      showExplanation.value = !showExplanation.value;
    };

    return {
      inputData,
      tableData,
      pagination,
      editButtonStatus,
      showExplanation,
      toggleExplanation,
      updatePage,
      doEdit,
      cancelEdit,
      doDelete,
      doSave,
    };
  },
};
